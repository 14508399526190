import { render, staticRenderFns } from "./TicketUpdate.vue?vue&type=template&id=0bcc1c9b&scoped=true"
import script from "./TicketUpdate.vue?vue&type=script&lang=js"
export * from "./TicketUpdate.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bcc1c9b",
  null
  
)

export default component.exports