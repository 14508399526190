<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogMarkingTicket"
        max-width="800px"
        scrollable
        persistent
        :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sửa thông tin ticket</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogMarkingTicket = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Point:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-text-field
                        dense
                        v-model="point_input"
                        type="number"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Trạng thái ticket:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4">
                      <v-radio-group v-model="status_input" row class="mt-0">
                        <v-radio
                          label="Không chấm muộn"
                          :value="status_ticket.PUNCTUAL"
                        ></v-radio>
                        <v-radio
                          label="Chấm muộn"
                          :value="status_ticket.LATE"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              @click="btnAddOrEditTicket(2)"
            >
              Update
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogMarkingTicket = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "TicketUpdate",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    ticket: {},
  },
  computed: {
    dialogMarkingTicket: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  data() {
    return {
      is_call_api: false,
      status_ticket: {
        PUNCTUAL: 1,
        LATE: 2,
      },
      point_input: null,
      status_input: null,
    };
  },
  watch: {
    dialogMarkingTicket(val) {
      if (val) {
        this.setDataForm();
      }
    },
  },
  methods: {
    setDataForm() {
      this.point_input = this.ticket.point;
      this.status_input = this.ticket.marking_status;
    },
    validateData() {
      let flat = true;
      if (this.point_input === null || this.point_input === "") {
        this.$toasted.error("Hãy nhập point !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.status_input === null || this.status_input === "") {
        this.$toasted.error("Hãy chọn trạng thái !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    btnAddOrEditTicket() {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let data = {
        point: this.point_input,
        marking_status: this.status_input,
      };
      let vm = this;
      this.is_call_api = true;
      ApiService.post(
        "prep-app/marking-ticket/" + this.ticket.id + "/update-field",
        data
      )
        .then(function (res) {
          if (res.status === 200) {
            vm.$toasted.success("Thêm thành công !!", {
              theme: "toasted-primary",
              position: "top-right",
              duration: 4000,
            });
            vm.is_call_api = false;
            vm.$emit("resetData", data);
            vm.dialogMarkingTicket = false;
          }
        })
        .catch(function (error) {
          vm.is_call_api = false;
          if (error.response.status === 422) {
            let data_response_error =
              error.response.data.error.message.split(".<br />");
            vm.showError(data_response_error);
          }
        });
    },
  },
};
</script>

<style scoped></style>
