<template>
  <div>
    <!--    <div style="background-color: rgb(243 246 249)">-->
    <!--      <h1 class="mb-5" style="color: #3F4254">Marking Tickets</h1>-->
    <!--    </div>-->
    <loading :active.sync="is_call_api"></loading>
    <!--    <div class="pt-5 pl-5 pr-5">-->
    <div>
      <v-row>
        <v-col cols="9">
          <v-row>
            <v-col cols="4" class="d-flex align-items-center pb-0">
              <label class="text-body-1 font-weight-medium mb-6">Marker:</label>
              <v-autocomplete
                  class="pl-4"
                  outlined
                  rounded
                  color="blue-grey lighten-2"
                  dense
                  chips
                  multiple
                  deletable-chips
                  :items="marker_tags"
                  item-text="name"
                  item-value="id"
                  v-model="marker_search_input"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" class="d-flex align-items-center pb-0">
              <label class="text-body-1 font-weight-medium mb-6">Course:</label>
              <v-autocomplete
                  class="pl-4"
                  outlined
                  rounded
                  color="blue-grey lighten-2"
                  dense
                  chips
                  multiple
                  clearable
                  deletable-chips
                  :items="course_tags"
                  item-text="name.vi"
                  item-value="id"
                  v-model="course_search_input"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" class="d-flex align-items-center pb-0">
              <label class="text-body-1 font-weight-medium mb-6">Class:</label>
              <v-autocomplete
                  class="pl-4"
                  outlined
                  rounded
                  color="blue-grey lighten-2"
                  dense
                  chips
                  multiple
                  deletable-chips
                  clearable
                  :items="class_tags"
                  item-text="name"
                  item-value="id"
                  v-model="class_search_input"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4" class="d-flex align-items-center pb-0">
              <label class="text-body-1 font-weight-medium mb-6">Skills:</label>
              <v-autocomplete
                  class="pl-4"
                  outlined
                  rounded
                  color="blue-grey lighten-2"
                  dense
                  :items="skill_tags"
                  item-text="name"
                  item-value="name"
                  v-model="skill_search_input"
              ></v-autocomplete>
            </v-col>
            <v-col
                cols="8"
                class="d-flex align-items-center pb-0 pt-0"
                v-if="view_ticket === 1"
            >
              <div>
                <label class="text-body-1 font-weight-medium"
                >Khoảng thời gian:</label
                >
              </div>
              <div class="d-flex ml-8">
                <label class="text-body-1 font-weight-medium mb-6 pt-4"
                >Từ:</label
                >
                <date-picker
                    style=""
                    class="ml-2 mt-3"
                    v-model="date_start_input"
                    value-type="format"
                    format="YYYY-MM-DD"
                />
              </div>
              <v-icon class="ml-2 mr-2 mb-3">mdi-arrow-right</v-icon>
              <div class="d-flex">
                <label class="text-body-1 font-weight-medium mb-6 pt-4"
                >Đến:</label
                >
                <date-picker
                    style=""
                    class="ml-2 mt-3"
                    v-model="date_end_input"
                    value-type="format"
                    format="YYYY-MM-DD"
                />
              </div>
            </v-col>
            <v-col cols="12" class="d-flex align-items-center pb-0">
              <label class="text-body-1 font-weight-medium mb-6"
              >Trang thái Deadline:</label
              >
              <v-radio-group
                  class="mt-0 pt-0"
                  v-model="status_deadline_filter"
                  row
              >
                <v-radio label="Tất cả" :value="status_deadline.all"></v-radio>
                <v-radio
                    label="Chấm muộn"
                    :value="status_deadline.marking_late"
                ></v-radio>
                <v-radio
                    label="Nộp muộn"
                    :value="status_deadline.submit_late"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <!--              <v-col :cols="(view_ticket === 1) ? 12 : 8" :class="(view_ticket === 1) ? 'text-center' : ''">-->
            <v-col :cols="view_ticket === 1 ? 12 : 8" class="text-center">
              <v-btn
                  color="cyan"
                  class="ma-2 font-weight-bold"
                  outlined
                  large
                  @click="btnFilterData"
              >
                <v-icon left>mdi-magnify</v-icon>
                Tìm kiếm
              </v-btn>
              <v-btn
                  color="red"
                  class="ma-2 font-weight-bold"
                  outlined
                  large
                  @click="btnResetFilterData"
              >
                <v-icon left>mdi-backup-restore</v-icon>
                Hủy lọc
              </v-btn>
            </v-col>
            <v-col cols="12" class="d-flex align-items-center pb-0">
              <label class="text-body-1 font-weight-medium mb-6"
              >Hiển thị tickets:</label
              >
              <v-radio-group class="mt-0 pt-0" v-model="view_ticket" row>
                <v-radio
                    :label=" 'Đã ẩn' + (view_ticket === 3 ? ' (' + data_pagination.total + ' ticket)' : '')"
                    :value="3"
                ></v-radio>
                <v-radio
                    :label=" 'Chưa chấm' + (view_ticket === 2 ? ' (' + data_pagination.total + ' ticket)' : '')"
                    :value="2"
                ></v-radio>
                <v-radio
                    :label=" 'Đã chấm' + (view_ticket === 1 ? ' (' + data_pagination.total + ' ticket)' : '')"
                    :value="1"
                ></v-radio>
                <v-radio
                    :label=" 'Chưa chấm & Đã chấm' + (view_ticket === 0 ? ' (' + data_pagination.total + ' ticket)' : '')"
                    :value="0"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col
                cols="12"
                class="d-flex align-items-center pb-0"
                v-if="view_ticket === 1"
            >
              <label class="text-body-1 font-weight-medium mb-6"
              >Lọc theo ngày:</label
              >
              <v-radio-group
                  class="mt-0 pt-0"
                  v-model="is_filter_by_marking_date"
                  row
              >
                <v-radio label="Học sinh nộp" :value="0"></v-radio>
                <v-radio label="Giáo viên đã chấm" :value="1"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="3" style="position: relative">
          <div style="width: 100%">
            <b-form-input
                style="width: 85%; margin-left: 15%"
                placeholder="Search student (name, phone, email)"
                v-model="search_input"
                @keyup.enter="enterSearchTicket()"
            ></b-form-input>
            <span
                style="display: inline-block; margin-left: 15%"
                class="text-subtitle-1 grey--text darken-1"
            >(Enter để tìm kiếm)</span
            >
          </div>
          <div
              style="
              position: absolute;
              bottom: 0;
              width: 100%;
              padding-right: 6%;
            "
              v-if="marker_search_input.length === 1"
          >
            <span class="text-body-1 font-weight-medium" style="float: right"
            >Total Point: {{ total_point }}</span
            >
          </div>
        </v-col>
        <v-col cols="12" v-if="ticket_selected.length > 0">
          <div class="float-left">
            <p class="text-body-1 font-weight-medium mb-6">
              Các ticket đã chọn:
              <template v-for="(ticket_id, i) in ticket_selected">
                <router-link
                    :to="{
                    name: 'MarkingTicketAnswer',
                    params: { id: ticket_id },
                  }"
                    :key="i"
                >
                  <span class="mr-2 text-decoration-underline"
                  >{{ ticket_id }},</span
                  >
                </router-link>
              </template>
            </p>
          </div>
          <div class="float-right">
            <v-btn
                outlined
                large
                color="#29B6F6"
                class="font-weight-bold"
                v-if="canAccess('assign-marker-ticket')"
                @click="openDialogAssignTicket()"
            >
              <v-icon left>mdi-account-edit</v-icon>
              Assign Ticket
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12">
          <div class="table-responsive">
            <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
            >
              <thead>
              <tr class="text-left">
                <!-- <th style="min-width: 250px" class="pl-7">
                    <span class="text-dark-75">course</span>
                  </th> -->
                <th></th>
                <th style="min-width: 100px">Ticket No</th>
                <th style="min-width: 120px">Submission Information</th>
                <th style="min-width: 120px">Lesson / Test</th>
                <th style="min-width: 120px">Submit Time</th>
                <th style="min-width: 100px">Marker</th>
                <th style="min-width: 100px">Partner</th>
                <th style="min-width: 100px">Status</th>
                <th style="min-width: 100px">Ticket Tag</th>
                <th style="min-width: 100px">Marking date</th>
                <th style="min-width: 100px">Tools</th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(marking_ticket, i) in marking_tickets">
                <tr v-bind:key="i">
                  <td>
                    <v-checkbox
                        :input-value="
                          checkValueCheckboxTicket(marking_ticket.id)
                        "
                        @click="setCheckboxTicket(marking_ticket.id)"
                    ></v-checkbox>
                  </td>
                  <td>
                      <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >#{{ marking_ticket.id }}</span
                      >
                  </td>
                  <td>
                      <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ marking_ticket.student_name }} (ID: #{{
                          marking_ticket.student_id
                        }})</span
                      >
                    <span
                        class="text-body-1 text-muted font-weight-bold d-block"
                    >
                        Course: {{ marking_ticket.course_name.vi }}
                      </span>
                    <span
                        class="text-body-1 text-dark-50 font-weight-bold d-block"
                        v-if="marking_ticket.class_name"
                    >
                        Class: {{ marking_ticket.class_name }}
                      </span>
                    <span
                        class="text-body-1 text-dark-50 font-weight-bold d-block text-decoration-underline indigo--text text--darken-1 cursor-pointer"
                        @click="openDialogMarkingTicket(marking_ticket, i)"
                    >
                        Point: {{ marking_ticket.point }}
                      </span>
                  </td>
                  <td>
                      <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >{{ marking_ticket.title }}</span
                      >
                    <span class="text-body-1 font-weight-bolder">
                        Skill: {{ marking_ticket.skill_name }}
                      </span>
                    <span
                        class="text-dark-75 d-block font-size-lg"
                        v-if="marking_ticket.marking_deadline"
                    >
                        <span class="font-weight-bold">Deadline:</span>
                        {{ marking_ticket.marking_deadline }}
                      </span>
                  </td>
                  <td>
                      <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ marking_ticket.time }}
                      </span>
                    <span
                        class="text-body-1 text-dark-50 font-weight-bold d-block"
                        v-if="marking_ticket.draft_number"
                    >
                        Draft Number: {{ marking_ticket.draft_number }}
                      </span>
                    <span
                        v-if="
                          marking_ticket.marking_status &&
                          marking_ticket.marking_status === 2
                        "
                        class="label label-lg label-inline font-weight-bold py-4 label-light-danger"
                    >
                        Chấm muộn
                      </span>
                    <span
                        v-if="
                          marking_ticket.submit_status &&
                          marking_ticket.submit_status === 2
                        "
                        class="label label-lg label-inline font-weight-bold py-4 label-light-warning"
                    >
                        Nộp muộn
                      </span>
                  </td>
                  <td>
                      <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ marking_ticket.marker_name }}
                      </span>
                  </td>
                  <td>
                      <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-if="marking_ticket.partner_name"
                      >
                        Partner: {{ marking_ticket.partner_name }}
                      </span>
                    <span
                        class="text-body-1 text-dark-50 font-weight-bold d-block"
                        v-if="marking_ticket.student_partners"
                    >
                        Student Partner: {{ marking_ticket.student_partners }}
                      </span>
                  </td>
                  <td>
                      <span
                          v-if="marking_ticket.status === 1"
                          class="label label-lg label-inline font-weight-bold py-4 label-light-danger"
                      >
                        Unmarked
                      </span>
                    <span
                        v-if="marking_ticket.status === 2"
                        class="label label-lg label-inline font-weight-bold py-4 label-light-warning"
                    >
                        Marking
                      </span>
                    <span
                        v-if="marking_ticket.status === 3"
                        class="label label-lg label-inline font-weight-bold py-4 label-light-success"
                    >
                        Marked
                      </span>
                  </td>

                  <td>
                    <div class="dropdown-tags d-flex flex-wrap">
                      <div
                          class="mx-1"
                          v-for="(tagsCurrent, indexTagsCurrent) in marking_ticket.tags"
                          :key="`tag-current-${indexTagsCurrent}`"
                      >
                        <v-tooltip left top v-if="canAccess('destroy-ticket')">
                          <template v-slot:activator="{ on, attrs }">
                            <span
                                v-bind="attrs"
                                v-on="on"
                                class="text-small d-inline-block text-truncate bg-success bg-opacity-10 rounded-xl py-1 px-3"
                                style="max-width: 150px;"
                            >
                              {{ tagsCurrent.name }}
                            </span>
                          </template>
                          <span>{{ tagsCurrent.name }}</span>
                        </v-tooltip>
                      </div>

                      <div class="position-relative">
                        <button
                            class="btn rounded-xl mx-1 py-1 px-3 btn-secondary d-flex align-items-center"
                            @click.stop="() => dropdownSlt = `dropdown-slt-${i}`"
                        >
                          Tags
                          <i class="text-small ml-2 fa fa-plus"/>
                        </button>

                        <div
                            class="position-absolute top-full right-0 bg-white zindex-2 shadow-lg rounded-xl"
                            :class="[
                              {'d-block': dropdownSlt == `dropdown-slt-${i}`},
                              {'d-none': dropdownSlt != `dropdown-slt-${i}`}
                          ]"
                        >
                          <p
                              class="pt-3 px-4 text-warning font-weight-bold"
                              v-if="tagsPerMultiSlt < tags.length"
                          >
                            chỉ chọn tối đa {{ tagsPerMultiSlt }} tag{{ tagsPerMultiSlt > 1 ? 's' : '' }}
                          </p>

                          <button
                              class="dropdown-item d-flex align-items-center"
                              :class="[{'bg-danger bg-opacity-10': marking_ticket.tags.find(e => e.id == tag.id)}]"
                              v-for="(tag, indexTag) in tags"
                              :key="`tag-marking-${indexTag}`"
                              @click="() => {
                              updateTags({
                                indexTicket: i,
                                markingId: marking_ticket.id,
                                currentTags: marking_ticket.tags,
                                newTag: marking_ticket.tags.find(e => e.id == tag.id) ? undefined : tag.id,
                                removeTag: marking_ticket.tags.find(e => e.id == tag.id) ? tag.id : undefined
                              });
                            }"
                          >
                            <p class="flex m-0">{{ tag.name }}</p>
                            <i v-if="marking_ticket.tags.find(e => e.id == tag.id)"
                               class="ms-5 text-white fa fa-times"/>
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>

                  <td>
                      <span
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          v-if="view_ticket === 1"
                      >
                        {{ marking_ticket.marking_time }}
                      </span>
                  </td>
                  <!-- <td class="pr-0 text-right pr-5"> -->
                  <td>
                    <div style="display: flex">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <router-link
                              :to="{
                                name: 'MarkingTicketAnswer',
                                params: { id: marking_ticket.id },
                              }"
                          >
                            <button
                                class="btn btn-icon btn-light-primary btn-sm"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="">mdi-marker</v-icon>
                                </span>
                            </button>
                          </router-link>
                        </template>
                        <span>Marking</span>
                      </v-tooltip>
                      <!--                          <v-tooltip top>-->
                      <!--                            <template v-slot:activator="{ on, attrs }">-->
                      <!--                              <button class="btn btn-icon btn-light-success btn-sm ml-2" v-bind="attrs" v-on="on" @click="assignMarker(marking_ticket, i)">-->
                      <!--                                <span class="svg-icon svg-icon-md svg-icon-primary">-->
                      <!--                                  <v-icon color="">mdi-account-edit</v-icon>-->
                      <!--                                </span>-->
                      <!--                              </button>-->
                      <!--                            </template>-->
                      <!--                            <span>Assign marker</span>-->
                      <!--                          </v-tooltip>-->
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <button
                              class="btn btn-icon btn-light-warning btn-sm ml-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="assignClass(marking_ticket, i)"
                          >
                              <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                              >
                                <v-icon color=""
                                >mdi-account-supervisor-circle</v-icon
                                >
                              </span>
                          </button>
                        </template>
                        <span>Assign class</span>
                      </v-tooltip>
                      <template v-if="view_ticket === 3">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <button
                                class="btn btn-icon btn-sm btn-light-success ml-2"
                                v-bind="attrs"
                                v-on="on"
                                @click="unHiddenMarkingTicket(marking_ticket.id)"
                            >
                                <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                  >mdi-eye-lock-open-outline</v-icon
                                  >
                                </span>
                            </button>
                          </template>
                          <span>Unhidden marking ticket</span>
                        </v-tooltip>
                      </template>
                      <template v-if="view_ticket !== 3">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <button
                                class="btn btn-icon btn-sm ml-2"
                                style="background-color: #b0bec5"
                                v-bind="attrs"
                                v-on="on"
                                @click="hiddenMarkingTicket(marking_ticket.id)"
                            >
                                <span
                                    class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color="white"
                                  >mdi-eye-off-outline</v-icon
                                  >
                                </span>
                            </button>
                          </template>
                          <span>Hidden marking ticket</span>
                        </v-tooltip>
                      </template>
                      <v-tooltip right v-if="canAccess('destroy-ticket')">
                        <template v-slot:activator="{ on, attrs }">
                          <button
                              class="btn btn-icon btn-light-danger btn-sm ml-2"
                              v-bind="attrs"
                              v-on="on"
                              @click="deleteMarkingTicket(marking_ticket.id)"
                          >
                              <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                              >
                                <v-icon color="">mdi-delete</v-icon>
                              </span>
                          </button>
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-if="!marking_tickets.length">
                <tr>
                  <td style="text-align: center" colspan="9">No data</td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </v-col>
        <v-col cols="6">
          <v-pagination
              style="float: left"
              color="#00BCD4"
              v-model="data_pagination.current_page"
              :length="data_pagination.total_pages"
              :total-visible="6"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogAssignTicket" max-width="1000px" scrollable>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Chọn giáo viên chấm bài</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogAssignTicket = false" right
              >mdi-close
              </v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="m-0 p-0">
                  <label style="font-size: 16px">Teachers</label>
                  <v-autocomplete
                      v-model="marker_id"
                      :disabled="isUpdating"
                      :items="marker_tags"
                      filled
                      chips
                      color="blue-grey lighten-2"
                      label=""
                      item-text="name"
                      item-value="id"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="removeMarkerTag('Marker')"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                            v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                              v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="m-0 p-0">
                  <p class="text-body-1 font-weight-medium mb-6">
                    Các ticket đã chọn:
                    <template v-for="(ticket_id, i) in ticket_selected">
                      <router-link
                          :to="{
                          name: 'MarkingTicketAnswer',
                          params: { id: ticket_id },
                        }"
                          :key="i"
                      >
                        <span class="mr-2 text-decoration-underline"
                        >{{ ticket_id }},</span
                        >
                      </router-link>
                    </template>
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
                class="btn btn-light-success text-uppercase mr-2"
                @click="btnAssignTicket"
            >
              Save
            </button>
            <button
                class="btn text-uppercase mr-2"
                style="color: #f64e60"
                @click="dialogAssignTicket = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row justify="center">
      <v-dialog v-model="dialogAddClass" max-width="1000px" scrollable>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Add Class</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogAddClass = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <label style="font-size: 16px">Class</label>
                  <v-autocomplete
                      v-model="class_id"
                      :disabled="isUpdating"
                      :items="class_tags"
                      filled
                      chips
                      color="blue-grey lighten-2"
                      label=""
                      item-text="name"
                      item-value="id"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="removeMarkerTag('Class')"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                            v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                              v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
                class="btn btn-light-success text-uppercase mr-2"
                @click="btnAddClass"
            >
              Add
            </button>
            <button
                class="btn text-uppercase mr-2"
                style="color: #f64e60"
                @click="dialogAddClass = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <TicketUpdate
        :show_dialog="dialogMarkingTicket"
        :ticket="marking_ticket"
        @setDialog="(e) => (this.dialogMarkingTicket = e)"
        @resetData="updateObjTicket"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";
import Ls from "@/core/services/jwt.service.js";
import handle_ls_search from "@/mixins/handle_ls_search.js";
import TicketUpdate from "./modal/TicketUpdate";

export default {
  name: "MarkingTicket",
  computed: {},
  mixins: [handle_ls_search],
  components: {
    TicketUpdate,
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      data_pagination: {
        total: 0,
        current_page: 1,
        total_pages: 1,
      },
      marking_tickets: [],
      marker_tags: [],
      marker_id: null,
      isUpdating: false,
      dialogAssignTicket: false,
      marking_ticket_id: null,
      indexMarkingTicket: null,
      search_input: "",
      course_tags: [],
      class_tags: [],
      skill_tags: [],
      marker_search_input: [],
      course_search_input: [],
      class_search_input: [],
      skill_search_input: null,
      dialogAddClass: false,
      class_id: null,
      timer_query: null,
      view_ticket: 2,
      date_start_input: null,
      date_end_input: null,
      is_filter_by_marking_date: 0,
      status_deadline_filter: 1,
      status_deadline: {
        all: 1,
        marking_late: 2,
        submit_late: 3,
      },
      ticket_selected: [],
      ticket_selectAll: false,
      total_point: 0,
      marking_ticket: {},
      dialogMarkingTicket: false,
      tags: [],
      tagsSlt: [],
      tagsPerMultiSlt: 1, // gía trị 1 để chỉ select dc 1 tag.
                          // hoặc tại hàm getTags set tagsPerMultiSlt = res.data.length sau khi get tags từ server.
                          // muốn select nhiều tags có thể set trực tiếp giá trị int lớn.
      dropdownSlt: null,
    };
  },
  //props: {
  //},
  created() {
    if (this.canAccess("view-ticket")) {
      if (!this.canAccess("marking-ticket")) {
        this.marker_search_input = JSON.parse(Ls.getUser()).name;
      }
      let data_search = this.getDataSearchByKey("marking_ticket");
      if (data_search) {
        this.marker_search_input = data_search.marker ? data_search.marker : this.marker_search_input;
        this.course_search_input = data_search.course ? data_search.course : this.course_search_input;
        this.class_search_input = data_search.class ? data_search.class : this.class_search_input;
        this.skill_search_input = data_search.skill ? data_search.skill : this.skill_search_input;
        this.date_start_input = data_search.date_start ? data_search.date_start : this.date_start_input;
        this.date_end_input = data_search.date_end ? data_search.date_end : this.date_end_input;
        this.view_ticket = data_search.view_ticket != null || data_search.view_ticket !== undefined ? data_search.view_ticket : this.view_ticket;
      }
      this.getListMarker();
      this.getAllCourse();
      this.getAllClass();
      this.getAllSkill();
      this.setData();
      this.getTags();
    } else {
      this.$router.push({name: "403"});
    }
  },
  mounted() {
    document.addEventListener('click', () => this.dropdownSlt = null);
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    view_ticket: function (val) {
      if (
          (this.date_start_input != null && this.date_end_input == null) ||
          (this.date_start_input == null && this.date_end_input != null)
      ) {
        this.date_start_input = null;
        this.date_end_input = null;
      }
      this.saveDataFilterToLs();
      if (val === 0) {
        this.getAllMarkingTicket();
      } else if (val === 1) {
        this.getAllMarkingTicketFinished();
      } else {
        this.getAllMarkingTicketUnfinished();
      }
    },
    "data_pagination.current_page": function (page) {
      if (this.view_ticket === 0) {
        this.getAllMarkingTicket(page);
      } else if (this.view_ticket === 1) {
        this.getAllMarkingTicketFinished(page);
      } else {
        this.getAllMarkingTicketUnfinished(page);
      }
    },
    // search_input: function(val) {
    //   this.actionSearch(val);
    // },
    is_filter_by_marking_date() {
      if (
          (this.date_start_input != null && this.date_end_input == null) ||
          (this.date_start_input == null && this.date_end_input != null)
      ) {
        this.date_start_input = null;
        this.date_end_input = null;
      }
      this.saveDataFilterToLs();
      this.getAllMarkingTicketFinished();
    },
  },
  methods: {
    async updateTags({indexTicket, markingId, currentTags, newTag, removeTag}) {

      let tagsSlt = currentTags.map(e => e.id);

      if (newTag && this.tagsPerMultiSlt == 1) {
        tagsSlt = [newTag];
      }

      if (newTag && this.tagsPerMultiSlt > 1 && tagsSlt.length < this.tagsPerMultiSlt) {
        tagsSlt = [...tagsSlt, newTag];
      }

      if (removeTag) {
        tagsSlt = tagsSlt.filter(e => e != removeTag);
      }

      const urlUpdateTags = `/prep-app/marking-ticket/${markingId}/tags`;
      const that = this;
      await ApiService.put(
          urlUpdateTags,
          {tag_ids: tagsSlt}
      ).then(function () {
          that.marking_tickets[indexTicket].tags = tagsSlt.map(e => that.tags.find((r)=>r.id == e) );
      });
    },
    async getTags() {
      const urlGetTags = '/prep-app/marking-ticket/tags';
      const that = this;
      await ApiService.get(urlGetTags).then(function (res) {
        if (res.status === 200) {
          that.tags = res.data;
          // dùng để set select dc nhiều tags cho ticket
          // that.tagsPerMultiSlt = res.data.length;
        }
      });
    },
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    setData() {
      if (this.view_ticket === 0) {
        this.getAllMarkingTicket();
      } else if (this.view_ticket === 1) {
        this.getAllMarkingTicketFinished();
      } else {
        this.getAllMarkingTicketUnfinished();
      }
    },
    async getAllMarkingTicket(page = 1, query = "") {
      let vm = this;
      let url = "prep-app/marking-ticket?page=" + page;
      if (query !== "") {
        url = "prep-app/marking-ticket?page=" + page + "&query=" + query;
      }
      let checkUrl = this.checkUrlMarkingTicket(url);
      this.is_call_api = true;
      await ApiService.get(checkUrl).then(function (res) {
        if (res.status === 200) {
          vm.marking_tickets = res.data.data;
          vm.data_pagination = res.data.meta.pagination;
          vm.total_point = res.data.total_point;
          vm.is_call_api = false;
        }
      });
    },
    async getAllMarkingTicketUnfinished(page = 1, query = "") {
      let vm = this;
      let is_hidden = this.view_ticket === 3 ? 1 : 0;
      let url =
          "prep-app/marking-ticket/unfinished?page=" +
          page +
          "&is_hidden=" +
          is_hidden;
      if (query !== "") {
        url = url + "&query=" + query;
      }
      let checkUrl = this.checkUrlMarkingTicket(url);
      this.is_call_api = true;
      await ApiService.get(checkUrl).then(function (res) {
        if (res.status === 200) {
          vm.marking_tickets = res.data.data;
          vm.data_pagination = res.data.meta.pagination;
          vm.total_point = res.data.total_point;
          vm.is_call_api = false;
        }
      });
    },
    async getAllMarkingTicketFinished(page = 1, query = "") {
      let vm = this;
      let url = "prep-app/marking-ticket/finished?page=" + page;
      if (query !== "") {
        url =
            "prep-app/marking-ticket/finished?page=" + page + "&query=" + query;
      }
      url = url + "&is_filter_marking_date=" + this.is_filter_by_marking_date;
      let checkUrl = this.checkUrlMarkingTicket(url);
      this.is_call_api = true;
      await ApiService.get(checkUrl).then(function (res) {
        if (res.status === 200) {
          vm.marking_tickets = res.data.data;
          vm.data_pagination = res.data.meta.pagination;
          vm.total_point = res.data.total_point;
          vm.is_call_api = false;
        }
      });
    },
    checkUrlMarkingTicket(url) {
      // if(!(this.marker_search_input === '' || this.marker_search_input === undefined || this.marker_search_input == null)) {
      //   url = url + '&marker='+this.marker_search_input;
      // }
      if (this.marker_search_input.length > 0) {
        url = url + "&marker_ids=" + this.marker_search_input.toString();
      }
      if (
          !(
              this.skill_search_input === "" ||
              this.skill_search_input === undefined ||
              this.skill_search_input == null
          )
      ) {
        url = url + "&skill_name=" + this.skill_search_input;
      }
      // if(!(this.class_search_input === '' || this.class_search_input === undefined || this.class_search_input == null)) {
      //   url = url + '&class_name='+this.class_search_input;
      // }
      if (this.class_search_input.length > 0) {
        url = url + "&class_ids=" + this.class_search_input.toString();
      }
      // if(!(this.course_search_input === '' || this.course_search_input === undefined || this.course_search_input == null)) {
      //   url = url + '&course_name='+this.course_search_input;
      // }
      if (this.course_search_input.length > 0) {
        url = url + "&course_ids=" + this.course_search_input.toString();
      }
      if (
          !(this.date_start_input === null || this.date_start_input === undefined)
      ) {
        url = url + "&date_start=" + this.date_start_input;
      }
      if (
          !(this.date_end_input === null || this.date_end_input === undefined)
      ) {
        url = url + "&date_end=" + this.date_end_input;
      }
      if (
          !(
              this.status_deadline_filter === null ||
              this.status_deadline_filter === undefined
          )
      ) {
        if (this.status_deadline_filter === this.status_deadline.marking_late) {
          url = url + "&use_filter_marking_late=1";
        } else if (
            this.status_deadline_filter === this.status_deadline.submit_late
        ) {
          url = url + "&use_filter_submit_late=1";
        }
      }
      return url;
    },
    async getAllCourse() {
      let vm = this;
      await ApiService.get("prep-app/courses/limit").then(function (response) {
        if (response.status === 200) {
          vm.course_tags = response.data;
        }
      });
    },
    async getAllClass() {
      let seft = this;
      await ApiService.get("prep-app/class").then(function (response) {
        if (response.status === 200) {
          seft.class_tags = response.data;
        }
      });
    },
    async getAllSkill() {
      let seft = this;
      await ApiService.get("prep-app/tag/list-skill").then(function (response) {
        if (response.status === 200) {
          seft.skill_tags = response.data;
        }
      });
    },
    getListMarker() {
      let self = this;
      ApiService.get("prep-app/markers").then(function (response) {
        if (response.status === 200) {
          self.marker_tags = response.data;
        }
      });
    },
    removeMarkerTag(type) {
      if (type === "Marker") {
        this.marker_id = null;
      } else if (type === "Class") {
        this.class_id = null;
      }
    },
    assignMarker(marking_ticket, indexMarkingTicket) {
      this.dialogAssignTicket = true;
      this.marker_id = marking_ticket.marker_id;
      this.marking_ticket_id = marking_ticket.id;
      this.indexMarkingTicket = indexMarkingTicket;
    },
    btnAddMarker() {
      let vm = this;
      let data = {
        marker_id: this.marker_id,
      };
      ApiService.post(
          "prep-app/marking-ticket/" + this.marking_ticket_id + "/assign-marker",
          data
      )
          .then(function (res) {
            if (res.status === 200) {
              vm.marking_tickets[vm.indexMarkingTicket].marker_id = vm.marker_id;
              vm.marking_tickets[vm.indexMarkingTicket].marker_name =
                  res.data.marker_name;
              vm.$toasted.success("Gắn thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.dialogAssignTicket = false;
            }
          })
          .catch(function (error) {
            console.log(error);
            vm.dialogAssignTicket = false;
          });
    },
    deleteMarkingTicket(marking_ticket_id) {
      let vm = this;
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.delete("prep-app/marking-ticket/" + marking_ticket_id)
              .then(function (res) {
                if (res.status === 202) {
                  vm.$toasted.success("Xóa thành công !!", {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration: 4000,
                  });
                  if (vm.view_ticket === 0) {
                    vm.getAllMarkingTicket(
                        vm.data_pagination.current_page,
                        vm.search_input
                    );
                  } else if (vm.view_ticket === 1) {
                    vm.getAllMarkingTicketFinished(
                        vm.data_pagination.current_page,
                        vm.search_input
                    );
                  } else {
                    vm.getAllMarkingTicketUnfinished(
                        vm.data_pagination.current_page,
                        vm.search_input
                    );
                  }
                }
              })
              .catch(function (error) {
                console.log(error);
              });
        }
      });
    },
    actionSearch(query) {
      let vm = this;
      if (this.timer_query) {
        clearTimeout(this.timer_query);
        this.timer_query = null;
      }
      this.timer_query = setTimeout(() => {
        if (vm.view_ticket === 0) {
          vm.getAllMarkingTicket(1, query);
        } else if (vm.view_ticket === 1) {
          vm.getAllMarkingTicketFinished(1, query);
        } else {
          vm.getAllMarkingTicketUnfinished(1, query);
        }
      }, 1200);
    },
    btnFilterData() {
      if (
          (this.date_start_input != null && this.date_end_input == null) ||
          (this.date_start_input == null && this.date_end_input != null)
      ) {
        this.$toasted.error("Hãy nhập đầy đủ khoảng thời gian chấm !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      this.saveDataFilterToLs();
      if (this.view_ticket === 0) {
        this.getAllMarkingTicket(1, this.search_input);
      } else if (this.view_ticket === 1) {
        this.getAllMarkingTicketFinished(1, this.search_input);
      } else {
        this.getAllMarkingTicketUnfinished(1, this.search_input);
      }
    },
    saveDataFilterToLs() {
      let marking_ticket = {
        marker: this.marker_search_input,
        course: this.course_search_input,
        class: this.class_search_input,
        skill: this.skill_search_input,
        view_ticket: this.view_ticket,
        date_start: this.date_start_input,
        date_end: this.date_end_input,
      };
      this.saveDataSearchToLs("marking_ticket", marking_ticket);
    },
    btnResetFilterData() {
      this.marker_search_input = [];
      this.course_search_input = [];
      this.class_search_input = [];
      this.skill_search_input = null;
      this.date_start_input = null;
      this.date_end_input = null;
      this.status_deadline_filter = this.status_deadline.all;
      this.btnFilterData();
    },
    assignClass(marking_ticket, indexMarkingTicket) {
      this.dialogAddClass = true;
      this.class_id = marking_ticket.class_id;
      this.marking_ticket_id = marking_ticket.id;
      this.indexMarkingTicket = indexMarkingTicket;
    },
    btnAddClass() {
      let vm = this;
      let data = {
        class_id: this.class_id,
      };
      ApiService.post(
          "prep-app/marking-ticket/" + this.marking_ticket_id + "/assign-class",
          data
      )
          .then(function (res) {
            if (res.status === 200) {
              vm.marking_tickets[vm.indexMarkingTicket].class_id = vm.class_id;
              vm.marking_tickets[vm.indexMarkingTicket].class_name =
                  res.data.class_name;
              vm.$toasted.success("Gắn thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.dialogAddClass = false;
            }
          })
          .catch(function (error) {
            console.log(error);
            vm.dialogAddClass = false;
          });
    },
    hiddenMarkingTicket(marking_ticket_id) {
      if (this.view_ticket === 3) {
        this.$toasted.info("Ticket này đang trong trạng thái ẩn !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        return;
      }
      let vm = this;
      Swal.fire({
        title: "Bạn chắc chắn ẩn ticket này ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Đúng, ẩn đi!",
      }).then((result) => {
        if (result.value) {
          ApiService.post(
              "prep-app/marking-ticket/" + marking_ticket_id + "/hidden"
          )
              .then(function (res) {
                if (res.status === 202) {
                  vm.$toasted.success("ẩn thành công !!", {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration: 4000,
                  });
                  if (vm.view_ticket === 0) {
                    vm.getAllMarkingTicket(
                        vm.data_pagination.current_page,
                        vm.search_input
                    );
                  } else if (vm.view_ticket === 1) {
                    vm.getAllMarkingTicketFinished(
                        vm.data_pagination.current_page,
                        vm.search_input
                    );
                  } else {
                    vm.getAllMarkingTicketUnfinished(
                        vm.data_pagination.current_page,
                        vm.search_input
                    );
                  }
                }
              })
              .catch(function (error) {
                console.log(error);
              });
        }
      });
    },
    enterSearchTicket() {
      let vm = this;
      if (vm.view_ticket === 0) {
        vm.getAllMarkingTicket(1, this.checkQuerySearch(this.search_input));
      } else if (vm.view_ticket === 1) {
        vm.getAllMarkingTicketFinished(
            1,
            this.checkQuerySearch(this.search_input)
        );
      } else {
        vm.getAllMarkingTicketUnfinished(
            1,
            this.checkQuerySearch(this.search_input)
        );
      }
    },
    checkQuerySearch(query) {
      if (query.substring(0, 1) === "#") {
        return "id-" + query.substring(1);
      }
      return query;
    },
    setCheckboxTicket(ticket_id) {
      if (this.ticket_selected.includes(ticket_id)) {
        this.ticket_selected = this.ticket_selected.filter(
            (item) => item !== ticket_id
        );
      } else {
        this.ticket_selected.push(ticket_id);
      }
    },
    checkValueCheckboxTicket(ticket_id) {
      return this.ticket_selected.includes(ticket_id);
    },
    openDialogAssignTicket() {
      this.dialogAssignTicket = true;
      this.marker_id = null;
    },
    btnAssignTicket() {
      let vm = this;
      let data = {
        marker_id: this.marker_id,
        tickets: this.ticket_selected,
      };
      this.is_call_api = true;
      ApiService.post("prep-app/marking-ticket/assign-tickets-for-marker", data)
          .then(function (res) {
            if (res.status === 200) {
              for (
                  let indexTicket = 0;
                  indexTicket < vm.marking_tickets.length;
                  indexTicket++
              ) {
                if (
                    vm.ticket_selected.includes(vm.marking_tickets[indexTicket].id)
                ) {
                  vm.marking_tickets[indexTicket].marker_id = vm.marker_id;
                  vm.marking_tickets[indexTicket].marker_name =
                      res.data.marker_name;
                }
              }
              vm.ticket_selected = [];
              vm.$toasted.success("Gắn thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.is_call_api = false;
              vm.dialogAssignTicket = false;
            }
          })
          .catch(function (error) {
            console.log(error);
            vm.is_call_api = false;
            vm.dialogAssignTicket = false;
          });
    },
    openDialogMarkingTicket(ticket, indexTicket) {
      if (this.canAccess("update-ticket")) {
        this.indexMarkingTicket = indexTicket;
        this.marking_ticket = JSON.parse(JSON.stringify(ticket));
        this.dialogMarkingTicket = true;
      }
    },
    updateObjTicket(data) {
      this.marking_tickets[this.indexMarkingTicket].point = data.point;
      this.marking_tickets[this.indexMarkingTicket].marking_status =
          data.marking_status;
      this.indexMarkingTicket = null;
      this.marking_ticket = {};
    },
    unHiddenMarkingTicket(marking_ticket_id) {
      let vm = this;
      Swal.fire({
        title: "Bạn chắc chắn bỏ ẩn ticket này ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Đúng vậy!",
      }).then((result) => {
        if (result.value) {
          ApiService.post("prep-app/marking-ticket/" + marking_ticket_id + "/un-hidden")
              .then(function (res) {
                if ([202, 200].includes(res.status)) {
                  vm.$toasted.success("bỏ ẩn thành công !!", {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration: 4000,
                  });
                  if (vm.view_ticket === 0) {
                    vm.getAllMarkingTicket(
                        vm.data_pagination.current_page,
                        vm.search_input
                    );
                  } else if (vm.view_ticket === 1) {
                    vm.getAllMarkingTicketFinished(
                        vm.data_pagination.current_page,
                        vm.search_input
                    );
                  } else {
                    vm.getAllMarkingTicketUnfinished(
                        vm.data_pagination.current_page,
                        vm.search_input
                    );
                  }
                }
              })
              .catch(function (error) {
                console.log(error);
              });
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}

.table td {
  vertical-align: middle;
}

.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}

.btn.btn-light-success i {
  color: #3699ff;
}

.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
